import { Image, InnerCol, OuterContainer, Text, SmartyPantsImage, TermsLink } from './PromoBar.style'
import { Col, Container, Row } from 'react-bootstrap'
import tarrow from '../../img/tarrow-mini.svg'
import { formatImageSet } from '../../util/helpers/image'
import smartyPants from '../../img/railcards-promo/railcard-retailing-smarty-pants-mobile.png'
import smartyPants2x from '../../img/railcards-promo/railcard-retailing-smarty-pants-mobile@2x.png'
import smartyPants3x from '../../img/railcards-promo/railcard-retailing-smarty-pants-mobile@3x.png'
import React from 'react'

export const PromoBar = () => {
  return (
    <OuterContainer>
      <Container>
        <Row className="d-lg-none">
          <Col className="d-flex justify-content-center">
            <SmartyPantsImage srcSet={formatImageSet([smartyPants, smartyPants2x, smartyPants3x])} alt="" />
          </Col>
        </Row>
        <Row>
          <InnerCol>
            <Image src={tarrow} />
            <Text>LIMITED OFFER: Buy your Railcard with us and get the equivalent of £10 off your next ticket. <TermsLink href="https://www.virgin.com/virgin-red/virgin-trains-ticketing-railcards-promotion-terms-and-conditions">Terms apply.</TermsLink></Text>
          </InnerCol>
        </Row>
      </Container>
    </OuterContainer>
  )
}
